import React, { useState } from "react";
import PropTypes from "prop-types";
import Error from "../../utils/Error.js";
// framework
import { Form } from "react-bootstrap";

const Input = ({
  defaultValue,
  maxLength,
  onChange,
  onchange,
  meta,
  onBlur,
  id,
  containerStyle,
  label,
  labelStyle,
  showLength,
  required,
  type,
  placeholder,
  disabled,
  isInvalid,
  isValid,
  readOnly,
  size,
  style,
  plaintext,
  as,
  rows,
  helperText,
  validateEmail,
  validatePhoneNumber,
  validateLink,
}) => {
  const [count, setCount] = useState(500);

  // counter for maxLength
  const characterCount = (event) => {
    setCount(maxLength - event.target.value.length);
  };
  // handle on change of input
  const handleChange = (event) => {
    onChange(event);

    if (showLength) {
      characterCount(event);
    }

    if (onchange) {
      onchange(event);
    }
  };

  // handle on change of input
  const handleBlur = (event) => {
    onBlur(event);
  };

  return (
    <Form.Group controlId={id} className={`${containerStyle} form-group`}>
      {label && (
        <Form.Label
          className={`${labelStyle} ${
            showLength ? "form-character-limit" : ""
          }`}
        >
          {label}
          {showLength && (
            <span>
              {count} out of {maxLength} Characters left
            </span>
          )}
        </Form.Label>
      )}
      <div className="form-control-wrap">
        <Form.Control
          required={required}
          type={type}
          placeholder={placeholder}
          defaultValue={defaultValue}
          disabled={disabled}
          isValid={isValid}
          isInvalid={isInvalid}
          readOnly={readOnly}
          size={size}
          onChange={handleChange}
          onBlur={handleBlur}
          className={`${style} form-label`}
          plaintext={plaintext}
          as={as}
          rows={rows}
          maxLength={maxLength}
          name={id}
        />
        {helperText && (
          <Form.Control.Feedback
            type={`${isValid && "valid"} ${isInvalid && "invalid"}`}
          >
            {helperText}
          </Form.Control.Feedback>
        )}

        {meta && defaultValue === "" ? (
          <Error show={meta.touched && !!meta.error} text={meta.error} />
        ) : meta && id === "phone" && validatePhoneNumber === false ? (
          <span className="text-danger d-block">
            Enter a Valid Phone Number
          </span>
        ) : meta && id === "website" && validateLink === false ? (
          <span className="text-danger d-block">Please Enter Valid Link</span>
        ) : meta && id === "email" && validateEmail === false ? (
          <span className="text-danger d-block">Please Enter Valid Email</span>
        ) : null}
      </div>
    </Form.Group>
  );
};

/**
 * as: The underlying HTML element to use when rendering the FormControl. 'input' | 'textarea'
 * label: set label text
 * id: set element id
 * name: set element name
 * type: set the type of input
 * placeholder: placeholder of input
 * defaultValue: set defaultValue
 * size: Input size variants 'sm' | 'lg'
 * style: Manually style the control
 * labelStyle: Manually style the label
 * containerStyle: Manually style the wrapper
 * helperText:  providing helper text
 * rows: set rows size for textarea
 * maxLength: set maxLength of input
 * disabled: Make the control disabled
 * isValid : Manually style the input as valid
 * isInvalid : Manually style the input as invalid
 * readOnly : Readlonly element
 * required : set is required or not
 * showLength : show character count
 * plaintext: Render the input as plain text. Generally used along side readOnly.
 * onChange: handlers from firing regardless of the rendered element.
 * onBlur: handlers from firing regardless of the rendered element.
 */

Input.propTypes = {
  as: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.string,
  labelStyle: PropTypes.string,
  containerStyle: PropTypes.string,
  helperText: PropTypes.string,
  rows: PropTypes.number,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  isValid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isInvalid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  showLength: PropTypes.bool,
  plaintext: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  meta: PropTypes.object,
};

Input.defaultProps = {
  as: "input",
  label: "",
  id: "",
  type: "text",
  placeholder: "",
  defaultValue: "",
  size: "",
  style: "",
  labelStyle: "",
  containerStyle: "",
  helperText: "",
  rows: null,
  maxLength: null,
  disabled: false,
  isValid: false,
  isInvalid: false,
  readOnly: false,
  required: false,
  showLength: false,
  plaintext: false,
  onChange: () => {},
  onBlur: () => {},
  meta: {},
};

export default Input;

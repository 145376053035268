import React from "react";
import PropTypes from "prop-types";

// styles
import "./legend.scss";

const Legend = (props) => {
  return (
    <div className={`legend-wrap ${props.style}`}>
      {props.label && <div className="legend-title">{props.label}</div>}
      {props.actions && <div className="legend-actions">{props.actions}</div>}
    </div>
  );
};

/**
 * label: set label text
 * style: Manually style the wrapper
 * actions: handlers from firing regardless of the rendered element.
 */

Legend.propTypes = {
  label: PropTypes.string,
  style: PropTypes.string,
  actions: PropTypes.object,
};

Legend.defaultProps = {
  label: "",
  style: "",
  actions: null,
};

export default Legend;

const Actions = {
  RESET_STATE: "RESET_STATE",

  POST_COVERPICTURE_DATA: "POST_COVERPICTURE_DATA",
  POST_COVERPICTURE_SUCCESS: "POST_COVERPICTURE_sUCCESS",
  POST_COVERPICTURE_FAIL: "POST_COVERPICTURE_FAIL",

  GET_COVERPICTURE_DATA: "GET_COVERPICTURE_DATA",
  GET_COVERPICTURE_SUCCESS: "GET_COVERPICTURE_sUCCESS",
  GET_COVERPICTURE_FAIL: "GET_COVERPICTURE_FAIL",

  GET_BANNER_DETAILS: "GET_BANNER_DETAILS",
  GET_BANNER_DETAILS_SUCCESS: "GET_BANNER_DETAILS_SUCCESS",
  GET_BANNER_DETAILS_FAIL: "GET_BANNER_DETAILS_FAIL",

  POST_BANNER_DATA: "POST_BANNER_DATA",
  POST_BANNER_DATA_SUCCESS: "POST_BANNER_DATA_SUCCESS",
  POST_BANNER_DATA_FAIL: "POST_BANNER_DATA_FAIL",

  UPDATE_BANNER_DATA: "UPDATE_BANNER_DATA",
  UPDATE_BANNER_DATA_SUCCESS: "UPDATE_BANNER_DATA_SUCCESS",
  UPDATE_BANNER_DATA_FAIL: "UPDATE_BANNER_DATA_FAIL",

  GET_BANNER_DATA: "GET_BANNER_DATA",
  GET_BANNER_DATA_SUCCESS: "GET_BANNER_DATA_SUCCESS",
  GET_BANNER_DATA_FAIL: "GET_BANNER_DATA_FAIL",

  DELETE_BANNER_BY_ID: "DELETE_BANNER_BY_ID",
  DELETE_BANNER_BY_ID_SUCCESS: "DELETE_BANNER_BY_ID_SUCCESS",
  DELETE_BANNER_BY_ID_FAIL: "DELETE_BANNER_BY_ID_FAIL",

  POST_ATTACHMENT_DATA: "POST_ATTACHMENT_DATA",
  POST_ATTACHMENT_DATA_SUCCESS: "POST_ATTACHMENT_DATA_SUCCESS",
  POST_ATTACHMENT_DATA_FAIL: "POST_ATTACHMENT_DATA_FAIL",

  REMOVE_BANNER_IMAGE: "REMOVE_BANNER_IMAGE",
  REMOVE_BANNER_IMAGE_SUCCESS: "REMOVE_BANNER_IMAGE_SUCCESS",
  REMOVE_BANNER_IMAGE_FAIL: "REMOVE_BANNER_IMAGE_FAIL",
};

export default Actions;

import React from "react";
import PropTypes from "prop-types";

// framework
import { Accordion } from "react-bootstrap";

// assets

// component
import { Check, InputDateRange } from "../../components";

const Filters = (props) => {
  // handle on change of input
  const handleChange = (value, event) => {
    props.onChange(value, event);
  };

  // handle on change date range
  const handleDateChange = (start, end) => {
    props.onDateChange(start, end);
  };

  return (
    <div className="accordion-wrap">
      <Accordion>
        {props.data.map((item, index) => {
          return (
            <Accordion.Item eventKey={index} key={index}>
              <Accordion.Header>{item.title}</Accordion.Header>
              <Accordion.Body>
                {(item.type === "checkbox" ||
                  item.type === "radio" ||
                  item.type === "switch") && (
                  <Check
                    id={item.title}
                    data={item.data}
                    type={item.type}
                    // defaultValue={item.defaultValue}
                    onChange={(value, event) => handleChange(value, event)}
                  />
                )}
                {item.type === "date-range" && (
                  <InputDateRange
                    id="events_range_date"
                    placeholder="Select Date"
                    dateFormat="YYYY/MM/DD"
                    includeTime={false}
                    // initialStartDate={""}
                    // initialEndDate={""}
                    onChange={(start, end) => {
                      handleDateChange(start, end);
                    }}
                    // disableDay={(date) => date.toDate() < new Date()}
                  />
                )}
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </div>
  );
};

/**
 * data: provide set of data
 * onChange: handlers from firing regardless of the rendered element.
 */

Filters.propTypes = {
  data: PropTypes.array,
};

Filters.defaultProps = {
  data: [],
};

export default Filters;
